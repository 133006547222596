import React, { useState, useEffect, useCallback, useRef } from "react";
import cx from "classnames";
import { listSize, strval } from "../../lib/util";
import getLogger from "../../lib/debug-logger";

import "../../styles/avm/dropdown.scss";

const log = getLogger("Dropdown", 3);

const Dropdown = (props) => {
  const {
    vals: values, // avoid input prop name 'values' (weird bug)
    labels = null,
    initValue = "", // original data value for initial selection and reset
    refValue = initValue, // updated data value, controls highlighting
    placeholder = "Select...",
    frozen = false, // user selection is blocked?
    disabled = false, // options not shown, value cannot be changed
    changeCallback = null,
  } = props;

  const [selectedValue, setSelectedValue] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const domElement = useRef(null);

  const handlePageClick = useCallback((ev) => {
    if (expanded && domElement && !domElement.current.contains(ev.target)) setExpanded(false);
  }, [expanded, domElement, setExpanded]);

  const toggleExpanded = useCallback(() => {
    !disabled && setExpanded(!expanded);}, [disabled, expanded, setExpanded]);

  const handleFocus = useCallback(() => {!disabled && setExpanded(true);}, [disabled, setExpanded])

  const handleBlur = () => {setExpanded(false);}

  // apply initial value
  useEffect(() => {
    setSelectedValue(initValue);
  }, [initValue, setSelectedValue]);

  useEffect(() => {
    if (listSize(values) && initValue && !values.includes(initValue)) {
      values.push(initValue); // no state change
    }
  }, [values, initValue]);

  useEffect(() => {
    document.addEventListener("click", handlePageClick, false);
    return () => {
      document.removeEventListener("click", handlePageClick, false);
    };
  });

  const handleSelect = (val) => {
    setExpanded(false);
    if (frozen) {
      return;
    }
    setSelectedValue(val);
    if (strval(val) !== strval(selectedValue)) {
      if (typeof changeCallback === "function") changeCallback(val);
    }
  };

  const getLabel = useCallback(
    (val) => {
      const n = listSize(labels);
      if (!n) return strval(val) || placeholder;
      const i = values.indexOf(val);
      return (n > i && labels[i]) || strval(val) || placeholder;
    },
    [values, labels, placeholder]
  );

  const getItemLabel = (i) => {
    return (listSize(labels) > i && labels[i]) || strval(values[i]) || placeholder;
  };

  useEffect(() => {
    log(`values: ${JSON.stringify(values)}`, `labels: ${JSON.stringify(labels)}`);
  }, [values, labels]);

  useEffect(() => {
    log(`selected value: ${selectedValue}, label: ${getLabel(selectedValue)}`);
  }, [selectedValue, getLabel]);

  if (!listSize(values)) return null;

  return (
    <div
      className={cx("dd-comp", props.className, {
        expanded,
        disabled
      })}
      ref={domElement}
    >
      <div
        className="dd-main"
        tabIndex={isNaN(props.tabIndex) ? undefined : props.tabIndex}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseDown={toggleExpanded}
      >
        <div
          className={cx("dd-value", {
            novalue: strval(selectedValue) === "",
            dirty: refValue !== selectedValue,
          })}
        >
          {getLabel(selectedValue)}
        </div>
        <div className="dd-button"></div>
      </div>
      {expanded ? (
        <div className="dd-menu">
          {values.map((val, i) => {
            const sel = val === selectedValue;
            const cls = cx("dd-option", {
              selected: sel,
              novalue: strval(val) === "",
              initial: val === props.refValue,
              disabled: frozen && !sel
            });
            return (
              <div
                key={i}
                className={cls}
                data-value={val}
                onClick={() => handleSelect(val)}
                role="option"
                aria-selected={sel ? "true" : "false"}
                title={getItemLabel(i)}
              >
                {getLabel(val)}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default Dropdown;
